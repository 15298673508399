import qs from "qs";

import {
  type AuthenticateMagicLinkResultErrorType,
  type IssueType,
} from "@medbillai/graphql-types";

export type LoginParams = {
  forceLogout?: boolean;
  magicLinkError?: AuthenticateMagicLinkResultErrorType;
  redirect?: string;
};

export type PreviewParams = {
  title: string;
  uri: string;
};

export type HipaaReleaseParams = {
  templateId?: string;
  eventId?: string;
};

export type HipaaViewReleaseParams = {
  templateReleaseId: string;
  templateDisplayName?: string;
};

export type HipaaErrorParams = {
  viewPdfError?: boolean;
};

export type FeedbackParams = {
  issueId: string;
  rating?: number;
  feedbackId?: string;
};

export type FeedbackDeepLinkParams = FeedbackParams & {
  showFeedback?: boolean;
};

// TODO - look into generating these

// Onboarding & auth
export const onboardingUrl = (): string => "/onboarding";
export const loginUrl = (params?: LoginParams): string => {
  if (!params) {
    return "/login";
  }
  return `/login?${qs.stringify(params)}`;
};
export const verifyEmailUrl = (email: string): string => {
  if (!email) {
    return "/login";
  }
  return `/verify-email?email=${encodeURIComponent(email)}`;
};
export const authenticateUrl = (token: string): string =>
  `/authenticate?token=${token}`;
export const identityCheckUrl = (): string => "/identity-check";

// Home Tabs
export const homeUrl = (): string => "/";
// Should only be used when navigating inside the tab router
// TODO: Because of the limitation of using headerLarge needing a stack router
// we cannot have a default initial route for the tab router, we need to update
// all routing to use /home but in the mean time because it is the default route
// we can use this till we update all routing, this will effect the web app
// minorly but it is a small fix
export const homeTabsUrl = (): string => "/home";
export const insuranceUrl = (): string => "/insurance";
export const issuesUrl = (): string => `/cases`;
export const billsUrl = (): string => `/bills`;

// Flow
export const documentUrl = (documentId: string): string =>
  `/document/${documentId}`;
export const documentPreviewUrl = () => "/document/preview";

export const issueUrl = (issueId: string, unread?: boolean): string =>
  `/case/${issueId}?unread=${unread}`;
export const issuePreviewUrl = (
  issueType: IssueType,
  firstMessage?: string,
): string => {
  let url = `/case/preview?issueType=${issueType}`;
  if (firstMessage) {
    url += `&firstMessage=${encodeURIComponent(firstMessage)}`;
  }
  return url;
};

// Bills
export const billUrl = (
  eobId: string,
  billName: string,
  decision: string,
): string => {
  return `/bill/${eobId}?billName=${billName}&decision=${decision}`;
};
export const billDisputeUrl = (eobId: string): string => {
  return `/bill-dispute?id=${eobId}`;
};

// Configuration / Onboarding
export const userNeedsUrl = (): string => "/user-needs";
export const usageScopeUrl = (): string => "/usage-scope";
export const howDidYouHearUrl = (): string => "/how-did-you-hear";
export const linkInsuranceUrl = (): string => "/link-insurance";
export const allowNotificationsUrl = (): string => "/allow-notifications";

// Hipaa Release
export const hipaaReleaseUrl = (params?: HipaaReleaseParams): string =>
  `/hipaa-release?${qs.stringify(params)}`;
export const hipaaViewReleaseUrl = (params?: HipaaViewReleaseParams): string =>
  `/hipaa-release/view-release?${qs.stringify(params)}`;
export const hipaaReleasePreviewUrl = (): string =>
  "/hipaa-release/release-preview";
export const hipaaReleaseSignatureUrl = (): string =>
  "/hipaa-release/release-signature";
export const hipaaReleaseSuccessUrl = (): string => "/hipaa-release/success";
export const hipaaReleaseErrorUrl = (params?: HipaaErrorParams): string =>
  `/hipaa-release/error?${qs.stringify(params)}`;
export const hipaaReleasePatientSelect = (): string =>
  "/hipaa-release/patient-select";
export const hipaaReleasePatientInformation = (): string =>
  "/hipaa-release/patient-information";
export const hipaaReleasePatientAddress = (): string =>
  "/hipaa-release/patient-address";
export const hipaaReleasePatientPhone = (): string =>
  "/hipaa-release/patient-phone";
export const hipaaReleasePatientEmail = (): string =>
  "/hipaa-release/patient-email";
export const hipaaReleasePatientInsurance = (): string =>
  "/hipaa-release/patient-insurance";
export const hipaaReleaseDependentInformation = (): string =>
  "/hipaa-release/dependent-information";
export const hipaaReleaseSubscriberInformation = (): string =>
  "/hipaa-release/subscriber-information";
export const hipaaReleaseAdditionalInformation = (): string =>
  "/hipaa-release/additional-information";

// Onboarding Insurance
export const onboardingInsuranceLinkUrl = (): string =>
  "/onboarding-insurance/select";
export const onboardingInsuranceLinkErrorUrl = (): string =>
  "/onboarding-insurance/error";
export const onboardingInsuranceUnsupportedPlanUrl = (): string =>
  "/onboarding-insurance/unsupported-plan";
// onboardingInsurance Agent
export const onboardingInsuranceWebAgentUrl = (): string =>
  "/onboarding-insurance/link";
export const onboardingInsuranceWebAgentMfaSelectUrl = (): string =>
  "/onboarding-insurance/link/mfa-select";
export const onboardingInsuranceWebAgentMfaCodeUrl = (): string =>
  "/onboarding-insurance/link/mfa-code";
export const onboardingInsuranceWebAgentSecurityQuestionUrl = (): string =>
  "/onboarding-insurance/link/security-question";
export const onboardingInsuranceWebAgentSuccessUrl = (): string =>
  "/onboarding-insurance/link/success";
export const onboardingInsuranceWebAgentAlternateInsuranceUrl = (): string =>
  "/onboarding-insurance/link/alternate-insurance";
// onboardingInsurance Flexpa
export const onboardingInsuranceFlexpaLinkUrl = (): string =>
  "/onboarding-insurance/connect/login";
export const onboardingInsuranceFlexpaCheckPlanUrl = (): string =>
  "/onboarding-insurance/connect/check-plan";
export const onboardingInsuranceFlexpaSuccessUrl = (): string =>
  "/onboarding-insurance/connect/success";
export const onboardingInsuranceFlexpaAlternateInsuranceUrl = (): string =>
  "/onboarding-insurance/connect/alternate-insurance";

// Onboarding Person
export const onboardingPersonNameUrl = (): string => "/onboarding-person/name";
export const onboardingPersonDobUrl = (): string =>
  "/onboarding-person/date-of-birth";

// Settings
export const settingsUrl = (): string => "/settings";
export const settingPreviewUrl = (params: PreviewParams): string => {
  return `/settings/preview?${qs.stringify(params)}`;
};
export const settingsReleaseListUrl = (): string => "/settings/release-list";
export const settingsAccountSettingsUrl = (): string => "/settings/account";
export const settingsDeleteAccountUrl = (): string =>
  "/settings/delete-account";

// Insurance
export const insuranceLinkUrl = (): string => "/insurance/select";
export const insuranceLinkErrorUrl = (): string => "/insurance/error";
export const insuranceLinkUnsupportedPlanUrl = (): string =>
  "/insurance/unsupported-plan";
// Insurance Agent
export const insuranceWebAgentLinkUrl = (): string => "/insurance/link";

export const insuranceWebAgentRelinkUrl = (linkId: string): string =>
  `/insurance/link/relink/${linkId}`;

export const insuranceWebAgentMfaSelectUrl = (): string =>
  "/insurance/link/mfa-select";
export const insuranceWebAgentMfaCodeUrl = (): string =>
  "/insurance/link/mfa-code";
export const insuranceWebAgentSecurityQuestionUrl = (): string =>
  "/insurance/link/security-question";
export const insuranceWebAgentSuccessUrl = (): string =>
  "/insurance/link/success";
export const insuranceWebAgentAlternateInsuranceUrl = (): string =>
  "/insurance/link/alternate-insurance";
export const insuranceWebAgentManagedMfaApprovalUrl = (): string =>
  "/insurance/link/managed-mfa";

// Insurance Flexpa
export const insuranceFlexpaLinkUrl = (): string => "/insurance/connect/login";
export const insuranceFlexpaCheckPlanUrl = (): string =>
  "/insurance/connect/check-plan";
export const insuranceFlexpaSuccessUrl = (): string =>
  "/insurance/connect/success";
export const insuranceFlexpaAlternateInsuranceUrl = (): string =>
  "/insurance/connect/alternate-insurance";

// Feedback
export const feedbackUrl = (params: FeedbackParams): string =>
  `/feedback?${qs.stringify(params)}`;
export const feedbackSuccessUrl = (rating?: number): string =>
  `/feedback/success?rating=${rating}`;
export const feedbackDeepLinkUrl = (params: FeedbackDeepLinkParams): string => {
  params.showFeedback = true;
  return `${issueUrl(params.issueId)}?${qs.stringify(params)}`;
};

// Person
export const peopleUrl = (): string => "/people";
export const peopleCreateUrl = (): string => "/people/create";
export const peopleEditUrl = (personId: string): string =>
  `/people/edit?id=${personId}`;

// Admin
export const shadowLoginUrl = (): string => "/shadow-login";

// Mobile only
export const previewUrl = (params: PreviewParams): string => {
  return `/preview?${qs.stringify(params)}`;
};
export const cameraUrl = (referenceId: string): string =>
  `/camera?referenceId=${referenceId}`;

// Admin URLs
export const adminCaseUrl = (caseId: string): string =>
  `/apps/8a3eab1a-a5e0-11ee-b17a-5b761e2a2d1f/Case/Manage%20Case?id=${caseId}`;

export const adminUserUrl = (userId: string): string =>
  `/apps/a294ec12-b0d3-11ee-ba13-07bdb4642acc/User/Manage%20User?=&id=${userId}`;
